import AssistedSchedule from "../../offline-appointment/assisted-schedule";
import React from "react";
import SelectLocale from "../../../components/Scheduling/SelectLocale";
import PartnerSelector from "../../../components/Scheduling/PartnetSelector";
import AddressInput from "../../../components/Scheduling/AddressInput";
import DateInput from "../../../components/Scheduling/DateInput";
import AdminSelector from "../../../components/Scheduling/AdminSelector";
import AdminInput from "../../../components/Scheduling/AdminInput";
import PatientData from "../../../components/Scheduling/PatientData";
import AppointmentSummary from "../../../components/Scheduling/AppointmentSummary";

interface AppointmentAssistedScheduleProps {
  id: string;
}

const AppointmentAssistedSchedule = ({
  id,
}: AppointmentAssistedScheduleProps) => {
  const steps = {
    0: <SelectLocale />,
    1: <PartnerSelector />,
    2: <AddressInput />,
    3: <DateInput />,
    4: <AdminSelector />,
    5: <AdminInput />,
    6: <PatientData />,
    7: <AppointmentSummary />,
  };

  return <AssistedSchedule id={id} steps={steps} />;
};

export default AppointmentAssistedSchedule;
