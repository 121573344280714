import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useAssistedSchedulingState from "../../stores/AssistedSchedulingStore";

import Switch from "@material-ui/core/Switch";
import Flex from "../Containers/Flex";
import OptionBox from "../../assets/styles/OptionBox";

const Wrapper = styled.div`
  margin-top: 5rem;
  width: 100%;
  height: 100%;
`;

const SelectLocale = () => {
  const [updateLocale, nextStep, isAlliance, setIsAlliance, isMedilinkPath] =
    useAssistedSchedulingState((state) => [
      state.selectLocale,
      state.nextStep,
      state.isAlliance,
      state.setIsAlliance,
      state.isMedilinkPath,
    ]);

  useEffect(() => {
    setIsAlliance(true);
  }, []);

  return (
    <Wrapper>
      <Flex direction="row" justify="center" align="center" gap="2rem">
        <OptionBox
          variant="outlined"
          onClick={() => {
            updateLocale("cl");
            nextStep(!isAlliance);
          }}
        >
          Chile
        </OptionBox>
      </Flex>
    </Wrapper>
  );
};

export default SelectLocale;
